import React from 'react'

const Privacy = () => {
  return (
    <div>
        <div className="privacy-container">
            <i style={{borderBottom: "2px solid black",fontSize:"20px",fontWeight:"bold"}}>Privacy Policy</i>
            <a>LionOrbit Tech Solutions Pvt. Ltd. is committed to protecting the privacy of our users. This 
                Privacy Policy outlines the types of personal information we receive and collect when you 
                use our mobile application ("Truhealthz"), how we use and safeguard that information, and 
                your choices regarding your information.
            </a>
        </div>
        <div style={{padding:"20px"}}>
            <b className='header'>1. Information We Collect</b>
            <p>Our Truhealthz App may collect the following types of information:</p>
            <div style={{padding:"10px"}}>
                <b className='smheader'>Personal Information: </b>
                <a>We may collect personal information such as your name, 
                email address, and other contact information when you voluntarily provide it to us.</a>
            </div>
            <div style={{padding:"10px"}}>
                <b className='smheader'>Health Data: </b>
                <a>Our App utilizes remote Photoplethysmography (rPPG) technology to 
                gather health vitals or biomarkers. This may include heart rate, blood oxygen levels, 
                and other relevant health metrics.</a>
            </div>
            <div style={{padding:"10px"}}>
                <b className='smheader'>Usage Information: </b>
                <a>We may collect information about your interactions with the 
                App, such as the features you use, the pages you visit, and the frequency and duration 
                of your activities.</a>
            </div>
            <b className='header'>2. Use of Information</b>
            <p>We may use the collected information for the following purposes:</p>
            <div style={{padding:"10px"}}>
                <b className='smheader'></b>
                <a>To provide and improve our services, including personalized health insights and risk 
                scoring based on the collected health data.</a>
            </div>
            <div style={{padding:"10px"}}>
                <b className='smheader'></b>
                <a>To communicate with you, respond to your inquiries, and provide customer support.</a>
            </div>
            <div style={{padding:"10px"}}>
                <b className='smheader'></b>
                <a>To analyze trends, monitor usage patterns, and enhance the overall user experience.</a>
            </div>
            <b className='header'>3. Data Accuracy and Disclaimer</b>
            <p>While we strive to provide accurate health information and insights, it is important to note the following:</p>
            <div style={{padding:"10px"}}>
                <b className='smheader'></b>
                <a>The information provided by the App is intended for informational purposes only and 
                should not be considered as medical advice or diagnosis.</a>
            </div>
            <div style={{padding:"10px"}}>
                <b className='smheader'></b>
                <a>We do not guarantee the accuracy, completeness, or reliability of the health 
                information provided by the App.</a>
            </div>
            <div style={{padding:"10px"}}>
                <b className='smheader'></b>    
                <a>Users should consult with a registered healthcare practitioner before making any 
                healthcare decisions or changes based on the information provided by the App.</a>
            </div>
            <b className='header'>4. Data Security</b>
            <p>We implement industry-standard security measures to protect your personal information from
            unauthorized access, disclosure, alteration, or destruction.</p>
            <b className='header'>5. Third-Party Services</b>
            <p>Our App may integrate with third-party services, such as analytics providers or healthcare 
            partners. These third parties may collect information about your use of the App, subject to 
            their own privacy policies.</p>
            <b className='header'>6. Changes to Privacy Policy</b>
            <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be
            effective immediately upon posting the updated Privacy Policy on the App.</p>
            <b className='header'>7. Contact Us</b>
            <p>If you have any questions or concerns about our Privacy Policy or data practices, please 
            contact us at info@lionorbit.com.</p>
            <p>By using our App, you consent to the collection and use of your information as described in 
            this Privacy Policy.</p>
        </div>
    </div>
  )
}

export default Privacy