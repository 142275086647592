// src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import TableComponent from './SimpleTable';
import moment from 'moment';
import { SortOrder } from 'react-data-table-component';
import Privacy from './Privacy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './component/Login';
//https://api.truhealthz.com/api/Authentication/BRAZIL/Login
const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Make a POST request to fetch data
    // axios.post('https://api.truhealthz.com/api/User/GetAllUsers', {})
    axios.post('https://api.truhealthz.com/api/User/BRAZIL/GetAllUsers', {})
      .then((response) => {
        // Log the response to check its structure
        console.log(response?.data["data"]);
        let finalData = response?.data["data"];

        // Filter out rows where roleId is 1
        const filteredData =finalData?.filter(user => user.roleId !== 1);
        // Set data if the response structure is as expected
        if (Array.isArray(filteredData)) {
          setData(filteredData);
        } else {
          setError('Unexpected data format');
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        setLoading(false);
      });
  }, []);


  if (loading) {
    return <div className="App">Loading...</div>;
  }

  if (error) {
    return <div className="App">{error}</div>;
  }

  const columns = [
    {
      Header: 'User Id',
      accessor: 'userId'
    },
    {
      Header: 'Created Date',
      accessor: 'createdDate',
      Cell: ({ value }) => moment(value).format('DD-MM-YYYY')
    },
    {
      Header: 'Name',
      accessor: 'firstName'
    },
   
    {
      Header: 'BMI',
      accessor: 'bmi'
    },
    {
      Header: 'BP',
      accessor: 'bloodPressure'
    },
    {
      Header: 'Diabities Risk',
      accessor: 'diabitiesRiskScore'
    },
    {
      Header: 'Depression',
      accessor: 'depress'
    },
    {
      Header: 'Stress Level',
      accessor: 'stressLevel'
    },
    {
      Header: 'Cardio Risk',
      accessor: 'cardio',
    },
    
  ]

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/all_user" element={<TableComponent columns={columns} data={data} />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
};

export default App;
