// src/TableComponent.js
import React, { useState } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import './SimpleTable.css';
import { AiOutlineLogout } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const TableComponent = ({ columns, data }) => {
  const [searchInput, setSearchInput] = useState('');
  const navigate=useNavigate()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of rows, we'll use page
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Set the initial page index to 0
    },
    useGlobalFilter, // Use global filter
    useSortBy,
    usePagination
  );

  const handleSearch = e => {
    const value = e.target.value || '';
    setGlobalFilter(value);
    setSearchInput(value);
  };

  return (
    <>
    <div className='bg-light row d-flex align-items-center px-4' style={{height:"64px"}}>
<div className='col-md-6'></div>
<div className='col-md-6  d-flex justify-content-end'>
  <button className='btn bg-white border' style={{fontWeight:600}} onClick={()=>navigate("/")}><AiOutlineLogout size={20} /> <span>Logout</span></button>
</div>
      </div>
    <div className='App'>
      
    <div className="table-container">
      <input
      style={{padding:"10px"}}
        value={searchInput}
        onChange={handleSearch}
        placeholder="Search"
        className="search-input"
      />
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <div className="table-footer">
          Total Rows: {data.length}
        </div>
      </div>
    </div>
  </div>
    </>
    
  );
};

export default TableComponent;
