import React, { useState } from 'react'
import { UseFormValidations } from './UseFormvalidation'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GoEye, GoEyeClosed } from 'react-icons/go';

const Login = () => {
    const navigate=useNavigate()
  const [password, setPassword] = useState(true);
  const submit=async()=>{
    let jsonObjects={
      email:data?.email,
      password:data?.password
    }
    let res = await axios.post("https://api.truhealthz.com/api/Authentication/BRAZIL/Login",jsonObjects)
    sessionStorage.setItem("token",res?.data?.token||"")
    
    if(res?.data?.status){
      navigate("/all_user")
    }
  }
    const {data,errors,handleSubmit,handleEmailChange,handleChange}=UseFormValidations({
        initialValues: {
            email: "",
            password: "",
          },
          validationSchema: {
            email: {
              required: {
                value: true,
                message: "Please enter a valid e-mail",
              },
              pattern: {
                value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                message: "Please enter a valid E-mail",
              },
            },
            password: {
              required: {
                value: true,
                message: "Please enter the correct password",
              },
            },
          },
          submit:submit
    })
    const handlePass = () => {
        setPassword(!password);
      };
    const returnValue=(key)=>{
        return data?.[key]&&data?.[key]?data?.[key]:""
          }
          const returnError=(key)=>{
            return errors?.[key]&&errors?.[key]?"form-control p-3 text_input border border-danger":"form-control p-3 text_input"
              }
  return (
    <div className='banner container d-flex justify-content-center align-items-center '  style={{height:"100vh"}}>
     
<form className='opacity container_login col-md-4 border p-3'  onSubmit={handleSubmit}>

<div className=' d-flex justify-content-center align-items-center '>
    {/* <img src={Logo} sizes='100%' className='img-fluid logo_img' /> */}
    </div>
<div className='loginText py-3'>Login to your account</div>
<div className='py-2'>
    <label className='text'>Email Address</label>
    <input className={returnError("email")}value={returnValue("email")} placeholder='Email address'onChange={handleEmailChange("email")} />
</div>
<div className='py-2'>
<label className='text col-md-12'>Password</label>
<div className="password-container ">

                <input
                  type={password ? "password" : "text"}
                  className={returnError("password")}value={returnValue("password")} placeholder='password'
                 
                  onChange={handleChange("password")}
                />
                {data?.password&&<span onClick={handlePass} className="eye-icon">
                  {password ? <GoEyeClosed color='black'/> : <GoEye color='black'/>}
                </span>}
              </div>
   </div>

<div className='py-2'>
    <button className='button border py-2 col-md-12  btn bg-light'>Login</button>
</div>
</form>
    </div>
  )
}

export default Login